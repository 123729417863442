import React from 'react';

import exportIcon from './img/ic-export.svg';
import planet from './img/ic-planet.svg';
import policy from './docs/policy.pdf'
import './Footer.css'

import {Link} from "react-router-dom";

export function Footer() {
    return(
        <div id="footer">
            <div className="container">
                <div style={{marginRight: 'auto'}} className="landing-contacts__col">
                    <p className="landing-contacts__p">2022 ООО “ЛССР”. Все права защищены</p>
                    <div className="landing-contacts__row landing-contacts__row1">
                        <img className="landing-contacts__img1" src={exportIcon}/>
                        <p className="landing-contacts__p1">ПО возможно использовать на условиях безвозмездного лицензионного договора.</p>
                    </div>
                    <div className="landing-contacts__row landing-contacts__row2">
                        <img className="landing-contacts__img3" src={planet}/>
                        <p className="landing-contacts__p2">ПО распространяется в виде интернет-сервиса, специальные действия по установке ПО на стороне пользователя не требуются.</p>
                    </div>
                </div>
                <div className="landing-contacts__col">
                    <div className="landing-contacts__row landing-contacts__row3">
                        <Link to="/">Условия пользования</Link>
                        <a href={policy} download="download">Политика обработки персональных данных</a>
                    </div>
                    <p className="landing-contacts__p3">
                        Стоимость пользования решением определяется индивидуально.
                    </p>
                    <p className="landing-contacts__p4">
                        Обратитесь с запросом по почте <br/><a href="mailto:ceo@testu.online">ceo@testu.online</a>
                    </p>
                </div>
            </div>
        </div>
    );
}