import React from "react";
import "./Popup.css";

import checked from "./img/checked.svg"
import {useSelector} from "react-redux";

export default function Popup(){
    let isShowed = useSelector(state =>state.main.showBanner)
    return(
        <div className={isShowed ? "popup showed" : "popup"}>
            <img src={checked} alt=""/>
            <p>Изменения сохранены</p>
        </div>
    )
}