import React from "react";
import {useSelector} from "react-redux";
import {useLocation} from "react-router-dom";

export default function CreationTabBar(props){
    let isActive;
    let currentTest = useSelector(state => state.main.currentTest)
    let location = useLocation();
    let parts = location.pathname.split('/');
    function returnLink(window){
        let newLink = '';
        if(window === 0){
            if(parts.length === 3){
                newLink = '/test/' + parts[parts.length - 1]
            }
            if(parts.length === 4){
                newLink = '/test/' + parts[parts.length - 2]
            }
        }
        if(window === 1){
            if(parts.length === 3){
                newLink = '/test/' + parts[parts.length - 1] + '/scales'
            }
            if(parts.length === 4){
                newLink = '/test/' + parts[parts.length - 2] + '/scales'
            }

        }
        if(window === 2){
            if(parts.length === 3){
                newLink = '/test/' + parts[parts.length - 1] + '/questions'
            }
            if(parts.length === 4){
                newLink = '/test/' + parts[parts.length - 2] + '/questions'
            }

        }
        return newLink
    }

    return (
        <div className="creationBar">
            <button onClick={() =>{
                props.setCurrentWindow(0);
                window.history.pushState("", "", returnLink(0));
            }} className={'creationBarButton ' + (props.currentWindow === 0 ? 'active' : '') }>
                Основное
            </button>
            <button onClick={() =>{
                props.setCurrentWindow(1);
                window.history.pushState("", "", returnLink(1));
            }} className={'creationBarButton ' + (props.currentWindow === 1  ? 'active' : '')}>
                Шкалы<span>{currentTest.scales ?currentTest.scales.length : 0}</span>
            </button>
            <button onClick={() =>{
                props.setCurrentWindow(2);
                window.history.pushState("", "", returnLink(2));
            }} className={'creationBarButton ' + (props.currentWindow === 2  ? 'active' : '')}>
                Вопросы<span>{currentTest.questions ? currentTest.questions.length : 0}</span>
            </button>
        </div>
    )
}