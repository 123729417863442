import React from "react";
import dots from "../img/dots.svg"
import editIcon from "../img/editIcon.svg"
import copyIcon from "../img/copyIcon.svg"
import deleteIcon from "../img/deleteIcon.svg"

import {Link} from "react-router-dom";
import loadTestsFromServer from "./TestsContent";
import {
    copyTest,
    deleteTest,
    deleteTests,
    setCurrentTest,
    setCurrentTestById
} from "../../../Redux/reducers/mainReducer";
import {useDispatch} from "react-redux";



export default function TestCard(props){
    let dispatch = useDispatch();



    let path = '/test/' + props.id;
    return (
        <Link to={path}>
            <div onClick={()=>{dispatch(setCurrentTestById(props.id))}} className="testCard">
                <div className="row">
                    <h3>{props.title}</h3>
                    <div className="dotsHolder">
                        <img src={dots}/>
                        <div className="dotsContent">
                            <div className="row">
                                <img src={editIcon}/>
                                <p>Редактировать</p>
                            </div>
                            <div onClick={(e) => {e.preventDefault();dispatch(copyTest(props.id))}} className="row">
                                <img src={copyIcon}/>
                                <p>Дублировать</p>
                            </div>
                            <div onClick={(e) => {e.preventDefault(); dispatch(deleteTest(props.id))}} className="row">
                                <img src={deleteIcon}/>
                                <p>Удалить</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row tags">
                    {props.tags.map((tag, tagOrderId) => {

                            return <div key={tagOrderId} className="tag">
                                {tag.name}
                            </div>


                    })}
                </div>
                <p>{returnTime(props.date)}</p>
            </div>
        </Link>
    )

}
function returnTime(date){
    let parts = date.slice(0, 10).split('-');
    return (parts[2] + '.' + parts[1] + '.' + parts[0])
}