import React from "react";
import TestCard from "./TestCard";



export default function TestsBlock(props){

         return (
             <div className="cardHolder">
                 <div className="container">
                     {props.tests ?
                         <div className="testsBlock">
                             {props.tests.map((item, id) => {
                                 return <TestCard key={id} number={id} id={item._id} title={item.name} tags={item.tags} date={item.createdAt} item={item}/>
                             })}
                         </div>
                         :
                         <div className="testsBlock">

                         </div>}
                 </div>
             </div>

         )

}