import React, {useEffect} from "react";
import Header from "../../components/Header/Header";
import TestsContent from "./atoms/TestsContent";
import "./MainPage.css"
import Popup from "../../components/Popup/Popup";


export default function MainPage(){
    return(
        <div className="testsPage">
            <Header />
            <TestsContent />
            <Popup/>
        </div>

    )
}